import React from "react";
import './about.css';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookOpen, faFishFins, faHandHoldingDollar, faLeaf, faShip } from "@fortawesome/free-solid-svg-icons";
import imgLogo from "../media/Cropped BNB Symbol Logo.png";
import imgSdgs from "../media/SDGs.JPG";

export default function Landing() {
    const navigate = useNavigate();

    return (
        <div className="main-page">
            <div className="main-background">
                <h1><b>Invest in Restoration.</b></h1>
                <h1><b>Get the Economic Return.</b></h1>
                <p>Investasi pada perbaikan lingkungan</p>
                <p>Tuai Keuntungan Ekonomi</p>
                <button onClick={()=>window.scrollTo(0,700)}>Get Started</button>

            </div>
            <div id="content-two">
                <div className="content-of-content-two">
                    <div className="gambar-content-two">
                       
                    </div>
                    <div className="deskripsi-gambar">
                        <h2>Investing in nature <br/>= mitigating risks.</h2>
                        <p>
                            <b>
                                It is becoming more crucial for leaders to take into <br/>account 
                                the three pillars of sustainability, without <br/>trading off one 
                                aspect for another. Instead of taking <br/>more of nature at stake, 
                                mitigating risks is mandatory <br/>by considering nature itself as a 
                                solution. 
                            </b>
                        </p>
                        <p>
                            <i>
                                Mempertimbangkan tiga pilar berkelanjutan menjadi<br/>
                                semakin penting bagi para pemimpin, tanpa<br/> 
                                mengorbankan satu aspek dengan aspek lainnya. Alih-alih<br/>
                                mengambil lebih banyak dari alam untuk dipertaruhkan,<br/>
                                kita perlu mengurangi risiko dengan mempertimbangkan<br/>
                                alam itu sendiri sebagai solusi.
                            </i>
                        </p>
                    </div>
                </div>
            </div>
            <div className="content-lanjutan">
                    <h2>PT Bumi Nautika berkelanjutan</h2>
                    <p>
                        We are a <b>youth-led research and consulting company,</b> specializing in<br/>
                        <b>coastal ecosystem restoration and economic value quantification.</b><br/>
                        Our aim is to provide <b>insightful data and recommendations</b> that help<br/>
                        our clients <b>take applicable steps toward a sustainable future.</b> We<br/>
                        leverage our <b>scientific expertise to deliver reliable results</b>
                    </p>
                    <p>
                        <i>
                            Kami adalah perusahaan penelitian dan konsultasi yang dipimpin oleh<br/>
                            kaum muda, dengan spesialisasi restorasi ekosistem pesisir dan kuantifikasi<br/>
                            nilai ekonomi. Tujuan kami adalah memberikan data dan rekomendasi<br/>
                            mendalam yang membantu klien kami mengambil langkah-langkah<br/>
                            aplikatif menuju masa depan berkelanjutan. Kami memanfaatkan keahlian<br/>
                            ilmiah kami untuk memberikan hasil yang dapat diandalkan.
                        </i>
                    </p>
            </div>
            <div className="content-visi">
                <div className="kartu-content-visi">
                    <h2>Our Logo</h2>
                    <img src={imgLogo} alt="Logo" width='135px' height="188px" />
                    <p>&#8212; Diving as a tool <br/>&#8212; Coral reef represent coastal ecosystem</p>
                    <a href="https://drive.google.com/file/d/1V_ouXx1qR_HGsbcGSVWiZoQmWzQWYm7d/view?usp=share_link" target="blank"><p>Take a Closer Look</p></a>
                </div>
                <div className="kartu-content-visi-blue">
                    <h2>Our Values</h2>
                    <div className="isi-kartu-content-visi-blue">
                        <p>To enhance economic<br/>
                        growth and coastal<br/>
                        community well-being<br/>
                        through environmental<br/>
                        risk reduction and<br/>
                        ecosystem restoration.</p>
                    </div>
                    <div className="isi-kartu-content-visi-blue-2">
                        <p><i>untuk meningkatkan<br/>
                        pertumbuhan ekonomi dan<br/>
                        kesejahteraan masyarakat<br/>
                        pesisir melalui pengurangan<br/>
                        risiko lingkungan dan<br/>
                        restorasi ekosistem.</i></p>
                    </div>
                </div>
                <div className="kartu-content-visi">
                    <h2>Aligning with Sustainable Development Goals (SDGs)</h2>
                    <img src={imgSdgs} alt="Sustainable Development Goals" width="195px" height="275px" />
                </div>
            </div>
            <div className="content-value">
                <h2>Our Values</h2>
                <div className="content-value-row">
                    <div className="kartu-content-value">
                        <div className="isi-kartu-content-value">
                            <h3>PURPOSE-DRIVEN</h3>
                            <p>
                                We are a group of scientists and doers, aiming beyond profits
                                 - benefits in a form of sustainable development for all.
                                </p>
                        </div>
                    </div>
                    <div className="kartu-content-value">
                        <div className="isi-kartu-content-value">
                            <h3>RELIABLE</h3>
                            <p>
                                Our services are based on scientific
                                research and well-known experts so
                                they can be used as reliable
                                references.
                            </p>
                        </div>
                    </div>
                    <div className="kartu-content-value">
                        <div className="isi-kartu-content-value">
                            <h3>ADAPTIVE</h3>
                            <p>
                                As a youth-led company, we can adjust to a wide range of demands in an agile and professional way.

                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content-services">
                <h2><a href="Services" style={{textDecoration: 'none', color: '#07295a'}}>Our Services</a></h2>
                <p>We offer bespoke services, including, but not limited to:</p>
                <div className="kolom-service">
                    <div onClick={()=>navigate('Services/Baseline-Development',{replace: false,})} className="card-kolom-service">
                        <FontAwesomeIcon icon={faFishFins} size="2x"/>
                        <h4>Baseline Development</h4>
                        <p>Pembuatan Baseline | Assessing coastal ecosystem <br/>potential</p>
                    </div>
                    <div onClick={()=>navigate('Services/Impact-Analysis')} className="card-kolom-service">
                        <FontAwesomeIcon icon={faShip} size="2x" />
                        <h4>Impact Analysis</h4>
                        <p>Analisis Dampak | Analyzing coastal ecosystem impact</p>
                    </div>
                </div>
                <div onClick={()=>navigate('Services/Ecosystem-Restoration')} className="kolom-service-2">
                    <FontAwesomeIcon icon={faLeaf} size="2x"/>
                    <h4>Ecosystem Restoration </h4>
                    <p>Restorasi ekosistem | Restoring a degraded ecosystem</p>
                </div>
                <div className="kolom-service">
                    <div onClick={()=>navigate('Services/Economic-Valuation')} className="card-kolom-service">
                        <FontAwesomeIcon icon={faHandHoldingDollar} size="2x"/>
                        <h4>Economic Valuation</h4>
                        <p>Valuasi ekonomi |  Quantifying the benefits of ecosystem <br/>services</p>
                    </div>
                    <div onClick={()=>navigate('Services/Training-and-Education')} className="card-kolom-service">
                        <FontAwesomeIcon icon={faBookOpen} size="2x"/>
                        <h4>Training and Education </h4>
                        <p>Pelatihan dan edukasi |  Capacity building as part of shared <br/>responsibility</p>
                    </div>
                </div>
            </div>
        </div>
    );
}