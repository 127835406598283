import React, { useState } from "react";
import './BottomBar.css';
import { useNavigate } from "react-router-dom";

export default function BottomBar() {
    return (
        <div className="main-body">

        </div> 
    )
};