import React from "react";
import './pages.css';

export default function NotFound() {
    return (
        <div className="halaman-utama">
            <br/> 
            <br/> 
            <br/> 
            <br/> 
            <h1>Aw Snap!</h1>
            <h2> Error 404</h2>
        </div>
    );
}