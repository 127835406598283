import React, { useState, useEffect } from "react";
import './pages.css';
import Modal from "../Modal";
import img1 from '../media/under-construction-2.png';

export default function Contact() {
    const [show, setShow] = useState(false);
    const hideModal = () => {
        setShow(false);
    };
    useEffect(() => {
        setShow(true);
    }, []);

    return (
        <div className="halaman-utama"> 
            <h1>Hello Bumi Nautika App</h1>
            <h2> Contact Page</h2>
            <Modal show={show} handleClose={hideModal}>
                <img src={img1} alt="under-construction"/>
                <p>This Site is under construction!</p>
            </Modal>
        </div>
    );
}