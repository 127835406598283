import React from 'react';
import './Modal.css'; // Create this file for modal styling

const Modal = ({ show, handleClose, children }) => {
    const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
        <div className='modal-content'>
            <section className="modal-main" >
                {children}
                <button onClick={handleClose}>Close</button>
            </section>
        </div>
    </div>
  );
};

export default Modal;
