import React from "react";
import './pages.css';
import './about.css';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookOpen, faFishFins, faHandHoldingDollar, faLeaf, faShip } from "@fortawesome/free-solid-svg-icons";

export default function Services() {
    const navigate = useNavigate();

    return (
        <div className="halaman-utama"> 
            <div className="content-services">
                <h2>Our Services</h2>
                <p>We offer bespoke services, including, but not limited to:</p>
                <div className="kolom-service">
                    <div onClick={()=>navigate('Baseline-Development',{replace: false,})} className="card-kolom-service">
                        <FontAwesomeIcon icon={faFishFins} size="2x"/>
                        <h4>Baseline Development</h4>
                        <p>Pembuatan Baseline | Assessing coastal ecosystem <br/>potential</p>
                    </div>
                    <div onClick={()=>navigate('Impact-Analysis')} className="card-kolom-service">
                        <FontAwesomeIcon icon={faShip} size="2x" />
                        <h4>Impact Analysis</h4>
                        <p>Analisis Dampak | Analyzing coastal ecosystem impact</p>
                    </div>
                </div>
                <div onClick={()=>navigate('Ecosystem-Restoration')} className="kolom-service-2">
                    <FontAwesomeIcon icon={faLeaf} size="2x"/>
                    <h4>Ecosystem Restoration </h4>
                    <p>Restorasi ekosistem | Restoring a degraded ecosystem</p>
                </div>
                <div className="kolom-service">
                    <div onClick={()=>navigate('Economic-Valuation')} className="card-kolom-service">
                        <FontAwesomeIcon icon={faHandHoldingDollar} size="2x"/>
                        <h4>Economic Valuation</h4>
                        <p>Valuasi ekonomi |  Quantifying the benefits of ecosystem <br/>services</p>
                    </div>
                    <div onClick={()=>navigate('Training-and-Education')} className="card-kolom-service">
                        <FontAwesomeIcon icon={faBookOpen} size="2x"/>
                        <h4>Training and Education </h4>
                        <p>Pelatihan dan edukasi |  Capacity building as part of shared <br/>responsibility</p>
                    </div>
                </div>
            </div>
        </div>
    );
}